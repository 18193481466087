import Head from "next/head";
import { useEffect } from "react";
import { useUser } from "@auth0/nextjs-auth0";
import Script from "next/script";

const Heap = () => {
  const { user } = useUser();

  const projectId = process.env.HEAP_PROJECT_ID;

  useEffect(() => {
    if (user && typeof window !== "undefined" && window.heap) {
      window.heap.identify(user.email);
      window.heap.addUserProperties({ name: user.name });
    }
  }, [user]);

  if (!projectId) return null;

  return (
    <Script
      id="heap-analytics"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
          heap.load("${projectId}");`,
      }}
    />
  );
};

export default Heap;
