import { useQuery, gql } from "@apollo/client";
import {
  createContext,
  useContext,
  useEffect,
  useState,
  Context,
  ReactNode,
} from "react";
import { AppUser, RolePermission } from "constants/types";

const GET_CURRENT_USER = gql`
  query GetCurrentUser {
    myAppUser {
      id
      name
      email
      isOwner
      avatarUrl
      appUserRole {
        id
        role {
          id
          name
          rolePermissions {
            totalCount
            nodes {
              id
              permission {
                id
                categoryId
                name
                category {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

type UserContextValue = {
  hasPermissions?: (names: Array<string>) => boolean;
  myAppUser?: AppUser;
};

export const UserContext: Context<UserContextValue> = createContext({});

export const useUserContext = () => useContext(UserContext);

const UserContextProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const { data } = useQuery(GET_CURRENT_USER);

  const [permissions, setPermissions] = useState(new Set());

  useEffect(() => {
    if (data?.myAppUser?.appUserRole?.role?.rolePermissions.nodes.length) {
      const rolePermissions =
        data.myAppUser.appUserRole.role.rolePermissions?.nodes;
      rolePermissions &&
        rolePermissions.map((rolePermission: RolePermission) => {
          setPermissions(permissions.add(rolePermission?.permission?.name));
        });
    }
    // eslint-disable-next-line
  }, [data]);

  const hasPermissions = (names: Array<string>) =>
    !names.map((name) => permissions.has(name)).includes(false);

  return (
    <UserContext.Provider
      value={{ hasPermissions, myAppUser: data?.myAppUser }}
    >
      {data ? children : ""}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
