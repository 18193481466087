export enum StorageType {
  local = "localStorage",
  session = "sessionStorage",
}

const fakeStorage = {
  getItem: () => {
    return null;
  },
  setItem: () => {},
  removeItem: () => {},
  length: 0,
  clear: () => {},
  key: () => null,
};

/**
 * Make it easier to get storage for store
 * @param Storage Which storage engine to use
 */
function getStorage(storageType: StorageType): Storage {
  if (typeof window === "undefined") {
    // Fake storage functions for server side rendering
    return fakeStorage;
  }

  if (storageType === StorageType.local) {
    return window.localStorage;
  } else if (storageType === StorageType.session) {
    return window.sessionStorage;
  } else {
    console.warn("Storage Type not identified", { storageType });
    return fakeStorage;
  }
}

export default getStorage;
