import EnglishUS from "content/compiled-locales/en-US.json";

const locales = Object.freeze({
  "en-US": EnglishUS,
});

function setupLocale(userLocale: string) {
  return (locales as any)[userLocale] ?? locales["en-US"];
}

export default setupLocale;
