import getStorage, { StorageType } from "./getStorage";
import getStore from "./getStore";

export enum LocalKeys {
  "" = "",
}

/**
 * An instance of local storage.
 * Stays even after user has closed the browser
 */
export const localStore = getStore<LocalKeys>(getStorage(StorageType.local));

export enum SessionKeys {
  CURRENT_ORG = "currentOrganization",
}

/**
 * An instance of session storage
 * Keys are valid only for current session
 */
export const sessionStore = getStore<SessionKeys>(
  getStorage(StorageType.session)
);
