/**
 * Get store interaction functions.
 * @param Storage Which storage engine to use
 */
function getStore<T extends string>(Storage: Storage) {
  return {
    /**
     * Get value from specified storage according to key
     * @param key Key stored
     * @param defaultValue Value returned if key-value pair not found
     * @param options
     */
    get(key: T, defaultValue?: unknown, options?: { parse: boolean }) {
      const { parse = false } = options ?? {};

      if (!key) {
        return console.error("Missing key for storage");
      }

      const item = Storage.getItem(key);
      if (!parse) {
        return item ?? defaultValue;
      }
      try {
        if (!item) {
          return defaultValue;
        }
        return JSON.parse(item) ?? defaultValue;
      } catch (err) {
        return defaultValue;
      }
    },
    /**
     * Set value to specified storage according to key
     * @param key Key stored
     * @param value value to be stored. If value is object, it will be stringified
     */
    set(key: T, value: unknown) {
      if (!key) {
        console.error("Missing key for storage");
        return;
      }

      if (typeof value === "object") {
        value = JSON.stringify(value);
      }

      if (typeof value !== "string") {
        return console.error("Value must be string or object");
      }

      Storage.setItem(key, value);
    },
    /**
     * Remove item specified by key
     * @param key Key stored
     */
    remove(key: T) {
      if (!key) {
        console.error("Missing key for storage");
        return;
      }
      Storage.removeItem(key);
    },
  };
}

export default getStore;
