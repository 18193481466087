import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
  split,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { graphqlBase, apiBase } from "./constants/url";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { SessionKeys, sessionStore } from "utils/store";

let cachedIdToken = null;

const orgId = sessionStore.get(SessionKeys.CURRENT_ORG, "");
const orgSwitchHeaders = {
  organization_id: orgId,
};

const requestIdToken = async () => {
  if (cachedIdToken) return;
  const res = await fetch("/api/session");
  if (res.ok) {
    const responseObject = await res.json();
    const { accessToken } = responseObject;
    cachedIdToken = accessToken;
  } else {
    window.location = "/api/auth/login";
  }
};

export const request = async ({ url, method, body, headers }) => {
  !cachedIdToken && (await requestIdToken());

  return await fetch(`${apiBase}${url}`, {
    method,
    headers: {
      authorization: `Bearer ${cachedIdToken}`,
      ...orgSwitchHeaders,
      ...headers,
    },
    body,
  });
};
const httpLink = createHttpLink({
  uri: graphqlBase,
});

const wsLink = process.browser
  ? new GraphQLWsLink(
      createClient({
        url: graphqlBase
          .replace("https://", "wss://")
          .replace("http://", "ws://"),
        lazy: true,
        connectionParams: async () => {
          return {
            authorization: cachedIdToken ? `Bearer ${cachedIdToken}` : "",
            ...orgSwitchHeaders,
          };
        },
      })
    )
  : null;

const splitLink = process.browser
  ? split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink
    )
  : httpLink;

const authMiddleware = setContext(async () => {
  await requestIdToken();
  return {
    headers: {
      authorization: `Bearer ${cachedIdToken}`,
      ...orgSwitchHeaders,
    },
  };
});

const client = new ApolloClient({
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
    query: {
      fetchPolicy: "network-only",
    },
    mutate: {
      refetchQueries: "active",
      awaitRefetchQueries: true,
      onQueryUpdated: (q) => q?.options?.variables?.skipRefetch !== true,
    },
  },
  link: ApolloLink.from([authMiddleware, splitLink]),
  cache: new InMemoryCache(),
});

export default client;
