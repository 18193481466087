import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useUser } from "@auth0/nextjs-auth0";
import { useQuery, useApolloClient } from "@apollo/client";
import { useRouter } from "next/router";

import GET_ORGANIZATION from "graphql/queries/getAllOrganizations.graphql";
import { Organization } from "constants/types";
import { SessionKeys, sessionStore } from "utils/store";
import useAutoRefetch from "hooks/useAutoRefetch";

interface OrganizationContext {
  organizations: Organization[];
  organization: Organization | undefined;
  switchOrg: Function;
  refetchOrgs: Function;
}

const OrganizationContext = createContext<OrganizationContext>({
  organizations: [],
  organization: undefined,
  switchOrg: () => {},
  refetchOrgs: () => {},
});

interface OrganizationProviderProps {
  children: React.ReactNode;
}

/**
 * These routes can be accessed without an organization.
 * Assuming that this will be a very small subsection of routes and they can be found on development easily,
 * choosing to store ones that do not require organization.
 */
const disabledRoutes = ["/onboard-user", "/email-verification"];

export const useOrganization = () => useContext(OrganizationContext);

const OrganizationProvider: FC<OrganizationProviderProps> = ({ children }) => {
  useAutoRefetch();
  const router = useRouter();
  const { user } = useUser();
  const {
    data,
    loading,
    error,
    refetch: refetchOrgs,
  } = useQuery<{
    organizations: { nodes: Organization[] };
    myCurrentOrgId: string;
  }>(GET_ORGANIZATION);

  const organizations = useMemo(() => data?.organizations?.nodes || [], [data]);
  const apolloClient = useApolloClient();

  const switchOrg = useCallback(
    (orgId?: string) => {
      sessionStorage.clear();
      sessionStore.set(SessionKeys.CURRENT_ORG, orgId);
      apolloClient.stop();
      apolloClient.clearStore();
      location.reload();
    },
    [apolloClient]
  );

  const myCurrentOrgId = data?.myCurrentOrgId;

  const ctxValue = useMemo(
    () => ({
      organization: organizations.find(({ id }) => id === myCurrentOrgId),
      organizations,
      switchOrg,
      refetchOrgs,
    }),
    [organizations, switchOrg, myCurrentOrgId, refetchOrgs]
  );

  useEffect(() => {
    if (loading || error) {
      return;
    }

    const { pathname } = router;

    if (!user?.email_verified && !disabledRoutes.includes(pathname)) {
      router.push("/email-verification");
    }

    if (
      !organizations.length &&
      user?.email_verified &&
      !disabledRoutes.includes(pathname)
    ) {
      router.push("/onboard-user");
    }
  }, [organizations, loading, error, router, user]);

  const redirectingToEmailVerificationPage =
    !user?.email_verified && router.pathname !== "/email-verification";

  const redirectingToOnboardPage =
    !myCurrentOrgId &&
    user?.email_verified &&
    router.pathname !== "/onboard-user";

  if (
    loading ||
    redirectingToOnboardPage ||
    redirectingToEmailVerificationPage
  ) {
    return <></>;
  }

  return (
    <OrganizationContext.Provider value={ctxValue}>
      {children}
    </OrganizationContext.Provider>
  );
};

export default OrganizationProvider;
