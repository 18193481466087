export const APP_NAME = "Oxley";

export const DROPDOWN_CONTAINER = "dropdowns";

export const ApproverModeLabels = {
  ANY: "Any of",
  ALL: "All of",
  MANAGER: "Only",
} as const;

export enum ApprovalStatus {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  NONE = "NONE",
}
