import { concat } from "lodash";
import { createContext, FC, useCallback, useContext, useMemo } from "react";

interface GlobalErrorContextValue {
  showApolloError: (error: Error) => void;
  showErrors: (errors: Error[]) => void;
}

const GlobalErrorContext = createContext<GlobalErrorContextValue | null>(null);

/**
 * Utility hook for using Error context and showing modal
 * @returns {GlobalErrorContextValue}
 */
export const useGlobalError = () => {
  const context = useContext(GlobalErrorContext);

  if (!context) {
    throw new Error(
      "useGlobalError must be used within a GlobalErrorContextProvider"
    );
  }

  return context;
};

// TODO: find better type for this
export const GlobalErrorContextProvider: FC<{
  children?: any | any[];
}> = ({ children }) => {
  const showErrors = useCallback((errors: Error[]) => {
    const errorMessage = errors.map((error) => error?.message).join("\n");
    alert(errorMessage);
  }, []);

  const showApolloError = useCallback(
    (apolloError: any) => {
      if (!apolloError) {
        return;
      }
      const errors = concat(
        apolloError.graphQLErrors ?? [],
        apolloError.clientErrors ?? [],
        apolloError.networkError,
        apolloError.networkError?.result?.errors ?? []
      );
      if (errors.length) {
        showErrors(errors);
      }
    },
    [showErrors]
  );

  const ctxValue = useMemo(
    () => ({
      showErrors,
      showApolloError,
    }),
    [showErrors, showApolloError]
  );

  return (
    <GlobalErrorContext.Provider value={ctxValue}>
      {children}
    </GlobalErrorContext.Provider>
  );
};
