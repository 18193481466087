import { useEffect, useCallback } from "react";
import { gql, useApolloClient, useSubscription } from "@apollo/client";
import { usePageVisibility } from "react-page-visibility";
import { throttle } from "lodash";

const AUDIT_LOG_SUBSCRIPTION = gql`
  subscription auditLogSubscription {
    auditLogEvents {
      time
    }
  }
`;

const useAutoRefetch = () => {
  const client = useApolloClient();
  const isVisible = usePageVisibility();

  useEffect(() => {
    if (isVisible) {
      client.refetchQueries({ include: "active" });
    }
    // eslint-disable-next-line
  }, [isVisible]);

  // eslint-disable-next-line
  const refetchQueries = useCallback(
    throttle(() => {
      if (isVisible) {
        client.refetchQueries({ include: "active" });
      }
    }, 1000),
    [isVisible]
  );

  useSubscription(AUDIT_LOG_SUBSCRIPTION, {
    shouldResubscribe: true,
    onSubscriptionData: () => refetchQueries(),
  });
};

export default useAutoRefetch;
