import { UserProvider } from "@auth0/nextjs-auth0";
import Head from "next/head";
import { ApolloProvider } from "@apollo/client";
import { AppProps } from "next/dist/shared/lib/router/router";
import { IntlProvider } from "react-intl";
import { useRouter } from "next/router";
import Heap from "components/Heap";
import ReactTooltip from "react-tooltip";
import UserContextProvider from "components/GlobalContexts/UserContext";
import setupLocale from "constants/setupLocale";
import { APP_NAME } from "constants/strings";
import OrganizationProvider from "components/GlobalContexts/OrganizationContext";
import { GlobalErrorContextProvider } from "components/Error/GlobalErrorContext";
import "utils/tailwind.css";
import client from "../apollo-client";

function CustomApp({ Component, pageProps }: AppProps) {
  const { locale = "en-US" } = useRouter();

  /**
   * The Layout returns elements that are not to be rerendered on route changes.
   * This helps components like Sidebar and Header to hold state between route changes
   * App component is the only component that does not change and hence we can only put this here.
   */
  const getLayout =
    (
      Component as {
        getLayout?: () => JSX.Element;
      }
    ).getLayout ?? ((page: unknown) => page);

  return (
    <>
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="description" content={APP_NAME} />
        <meta property="og:title" content={`Home | ${APP_NAME}`} />
        <meta property="og:description" content={APP_NAME} />
        <meta
          property="og:image"
          content="https://images.ctfassets.net/219eo7xm94hf/17joGjgyHizd1LOAJRgSJV/70cb15974c0ffea54891d32944d45ab9/Oxley_opengraph_1.png?fm=webp"
        />
      </Head>
      <IntlProvider locale={locale} messages={setupLocale(locale)}>
        <UserProvider>
          <ApolloProvider client={client}>
            <UserContextProvider>
              <OrganizationProvider>
                <GlobalErrorContextProvider>
                  <Heap />
                  <ReactTooltip
                    id="common"
                    multiline
                    place="top"
                    effect="solid"
                    backgroundColor="#2C3079"
                  />
                  {getLayout(<Component {...pageProps} />)}
                  <div id="modals" className="isolate" />
                </GlobalErrorContextProvider>
              </OrganizationProvider>
            </UserContextProvider>
          </ApolloProvider>
        </UserProvider>
      </IntlProvider>
    </>
  );
}

export default CustomApp;
